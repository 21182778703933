import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { url: String }

  connect() {
    console.log(this.identifier, "connected")
    const url = this.urlValue
    window.location.replace(url)
    // The embedded_edit_url expires after it's opened the first time.
    // You can regenerate a new embedded_edit_url by calling signWellEmbed.newEditUrl
  }
}

