import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const trustboxes = document.querySelectorAll('.trustpilot-widget');
    if(window.Trustpilot){
      trustboxes.forEach(trustbox => window.Trustpilot.loadFromElement(trustbox))
    }
  }
}


