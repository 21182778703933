/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import Rails from "@rails/ujs"
import { Turbo } from "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()


// Make accessible for Electron and Mobile adapters
window.Rails = Rails
window.Turbo = Turbo

// ActionCable Channels
import "./channels"


import "./src"

// Start Rails UJS
Rails.start()

import "./controllers";

import "@hotwired/turbo-rails"
document.addEventListener("turbo:load", function(event) {
  if (event.detail.url.includes('supervalu')) {
    console.log(event.detail)
    window.dataLayer = window.dataLayer || []
    function gtag(){dataLayer.push(arguments)};
    gtag('js', new Date())
    gtag('config', 'DC-4396925', {'page_location': event.detail.url});
  }
}, false)


document.addEventListener("turbo:load", function() {
  if (window.fathom) {
    window.fathom.trackPageview()
  }
})
