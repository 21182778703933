import Flickity from 'flickity';
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    const flkty = new Flickity( this.element, {
      contain: true,
      prevNextButtons: false,
      autoPlay: 4000
    });
    flkty.reloadCells();
    flkty.next();
    flkty.resize()
  }
}
