import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['tooltips']

  connect() {
    this.tooltipClass = this.data.get('class') || 'hidden'
  }

  show(event) {
    event.preventDefault()

    this.tooltipsTargets.forEach(target => {
      target.classList.toggle(this.tooltipClass)
    })
  }

  hide(event) {
    event.preventDefault()

    this.tooltipsTargets.forEach(target => {
      target.classList.add(this.tooltipClass)
    })

  }

}


