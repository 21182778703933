import Trix from "trix"
import "@rails/actiontext"
import { document } from "postcss";

Trix.config.blockAttributes.heading2 = {
    tagName: "h2",
    terminal: true,
    breakOnReturn: true,
    group: false
}

Trix.config.blockAttributes.heading3 = {
    tagName: "h3",
    terminal: true,
    breakOnReturn: true,
    group: false
}

addEventListener("trix-initialize", event => {
  const { toolbarElement } = event.target
  const h1Button = toolbarElement.querySelector("[data-trix-attribute=heading1]")
  if(!h1Button){return};

  h1Button.insertAdjacentHTML("afterend", `
    <button type="button" class="trix-button" data-trix-attribute="heading3" title="Heading 3" tabindex="-1" data-trix-active="">H3</button>
  `)

  h1Button.insertAdjacentHTML("afterend", `
    <button type="button" class="trix-button" data-trix-attribute="heading2" title="Heading 2" tabindex="-1" data-trix-active="">H2</button>
  `)
})
