import { Controller } from "@hotwired/stimulus"

function checkSession(target, url) {
  fetch(url)
    .then(response => response.json())
    .then(data => {
      if (!data["sales_force_id"]) {
        return;
      }
      target.value = data["sales_force_id"];
      return;
    })
    .catch(error => console.error(error))
}

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    this.setValue()
  }

  setValue() {
    const url = this.data.get('url') || '/pages.json';
    checkSession(this.fieldTarget, url);
  }
}
