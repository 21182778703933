import { Controller } from "@hotwired/stimulus";

export default class extends Controller {

  connect() {
    // dispatch window event
    window.dispatchEvent(new CustomEvent('connected', { detail: { message: 'element connect' } }));
  }
}

