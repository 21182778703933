
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [ "btn", "input" ];
  static classes = [ "active" ];

  connect() {
    this.setup();
  }

  toggle() {
    this.btnTargets.forEach((btn) => {
      btn.classList.remove(this.activeClass);
    });
    event.currentTarget.classList.add(this.activeClass);
    this.inputTarget.value = event.currentTarget.dataset.value;
  }

  setup() {
    this.btnTargets.forEach((btn) => {
      if (btn.dataset.value == this.inputTarget.value) {
        btn.classList.add(this.activeClass);
      }
    });
  }

}
