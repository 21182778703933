import { Controller } from "@hotwired/stimulus"

function checkSession(url) {
}

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
  }

  disconnect() {
  }
}
