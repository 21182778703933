import { Controller } from "@hotwired/stimulus";
import Cookies from 'js-cookie';

export default class extends Controller {

  static values = {
    cookieName: {type: String, default: "low_gdpr"},
    cookieExpiration: {type: Number, default: 182}
  }

  static classes = ["hidden"];

  connect() {
    // if there is no cookie, show the banner
    if (Cookies.get(this.cookieNameValue) == undefined)
  {
      return;
    }


    if (Cookies.get(this.cookieNameValue) == 'false') {
      // remove
      // Get all elements with the data-gdpr-script attribute
      const elementsToRemove = document.querySelectorAll('[data-gdpr-script]');
      // Loop through each element and remove it from the DOM
      elementsToRemove.forEach(element => {
        element.remove();
      });
    }  

    this.element.classList.add(this.hiddenClass)

  }

  agree() {
    Cookies.set(this.cookieNameValue, true, {
      expires: this.cookieExpirationValue,
      sameSite: 'lax'
    });

    this.element.classList.add(this.hiddenClass)
  }

  disagree() {
    Cookies.set(this.cookieNameValue, false, {
      expires: this.cookieExpirationValue,
      sameSite: 'lax'
    });
    // Get all elements with the data-gdpr-script attribute
    const elementsToRemove = document.querySelectorAll('[data-gdpr-script]');
    // Loop through each element and remove it from the DOM
    elementsToRemove.forEach(element => {
      element.remove();
    });

    this.element.classList.add(this.hiddenClass)
  }
}
