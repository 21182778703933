import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    clean: Boolean
  }

  connect() {
    if (this.cleanValue) {
      this.element.removeAttribute("hidden")
      this.element.removeAttribute("disabled")
    }
  }
}
