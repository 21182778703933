import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "toggle", "input", "covered", "uncovered", "percent"]
  static values = {
    welfare: Number,
  }
  static classes = [ "toggle" ]

  calculate() {
    this.toggle();
    const salary = this.inputTarget.value;
    const welfare = this.welfareValue;
    const percent = this.percentTarget.value;
    const covered = (salary * percent) - welfare;
    const uncovered = salary - (salary * percent);

    this.coveredTarget.innerText = `€${covered.toFixed(2)}`
    this.uncoveredTarget.innerText = `€${uncovered.toFixed(2)}`
  }

  toggle() {
    if (this.inputTarget.value > 10000) {
      this.toggleTarget.classList.remove(this.toggleClass);
    } else {
      this.toggleTarget.classList.add(this.toggleClass);
    }
  }
}


