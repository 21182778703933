import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    checked: Boolean,
  }

  connect() {
    // 
    if (this.checkedValue) {
      this.element.click()
    }
  }
}

