import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['match']


  update(e) {
    if (e.target.value == this.element.dataset.matchAttribute) {
      this.matchTarget.disabled = false
      this.matchTarget.hidden = false
    } else {
      this.matchTarget.disabled = true
      this.matchTarget.hidden = true
    }
  }
}
