import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["source", 'text' ]
  static values = { success: String }

  copy() {
    navigator.clipboard.writeText(this.sourceTarget.value)
    this.textTarget.innerText = this.successValue
  }
}

