import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "field", 'question' ]

  connect() {
    this.check()
  }

  check() {
    const parent = this.element
    let keys = []
    this.questionTargets.forEach(question => {
      question.dataset.selector.split(' ').forEach((selector) => {  
        keys[selector] = Array.from(parent.querySelectorAll(`[data-selector*="${selector}"]`)).some((field) => field.checked && field.value == 'true')
      })
    })
    for (const [key, value] of Object.entries(keys)) {
      const field = parent.querySelector(`[data-${key}]`)
      if (keys[`${key}`] == true) {
        field.removeAttribute('hidden')
        field.removeAttribute('disabled')
      } else {
        field.setAttribute('disabled', '')
        field.setAttribute('hidden', '')
      }

    }
  }
}
