import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  connect() {
    setTimeout(this.dispathEvent, 7000)
  }

  dispathEvent(){
   window.dispatchEvent(new CustomEvent("timer")) 
  }
}
