import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['bar', 'progress']

  connect() {
    const inputs = document.querySelectorAll('input[type=radio][required], input[type=radio].required,input[type=radio], input[required], select[required], textarea[required], input.required, select.required, textarea.required')

    inputs.forEach(input => {
      input.addEventListener('change', this.updateProgressBar) 
    })
  }

  addEvents() {
    const inputs = document.querySelectorAll('input, textarea[required], select[required]')

    inputs.forEach(input => {
      input.addEventListener('change', this.updateProgressBar) 
    })

  }

  updateProgressBar(e) {
    const validInput = (e.target.type != 'radio' && e.target.validity.valid || (e.target.type == 'radio' && e.target.checked))
    const inputs = document.querySelectorAll('input[type=radio][required], input[type=radio].required, select[required], textarea[required], input.required, select.required, textarea.required')
    const bar = document.querySelector("[data-target='progress-bar.bar']")
    let magicNumber = (10/inputs.length)
    if (magicNumber == Infinity || magicNumber == 'NaN') {
      return
    }
    let currentPercentage = parseFloat(bar.style.width)
    let percentageIncrease = currentPercentage +  magicNumber
    let percentageDecrease = currentPercentage -  magicNumber
    if (validInput) {
      bar.style.width = `${percentageIncrease}%`
    } else {
      bar.style.width = `${percentageDecrease}%`
    }
  }
}
