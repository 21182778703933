// Example usage:
// <div data-controller="tippy" data-tippy-content="Hello world"></div>

import { Controller } from "@hotwired/stimulus"
import tippy from "tippy.js";

export default class extends Controller {
  static values = {
    placement: {type: String, default: 'top' }
  }

  connect() {
    let options = {
      trigger: 'click',
      allowHTML: true,
      interactive: true,
      theme: 'indigo',
      arrow: true,
      placement: this.placementValue,
      animation: 'scale-extreme',
      duration: [200, 200],
    };
    this.tippy = tippy(this.element, options);
  }

  hide() {
    this.tippy.hide();
  }

  disconnect() {
    this.tippy.destroy();
  }
}

